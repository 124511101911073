import React from "react"

import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import styled from "styled-components"
import { useAddRegisterMutation } from "../components/features/api/authApi"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))
export default function SignUp() {
  const classes = useStyles()
  const [user, setUser] = React.useState("")
  const [pass, setPass] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [confirmPass, setConfirmPass] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [confirmEmail, setConfirmEmail] = React.useState("")
  const [errorMsg, setErrorMsg] = React.useState("")

  const [showPassword, setShowPassword] = React.useState(false)

  const token = false

  const [addRegister, { error, isLoading, status }] = useAddRegisterMutation()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    email !== confirmEmail
      ? setErrorMsg(`Those emails don't match. Try again.`)
      : pass !== confirmPass
      ? setErrorMsg(`Those passwords don't match. Try again.`)
      : await addRegister({ username: user, password: pass, email: email })

  }
  if (status === "fulfilled") {
    navigate("/email-confirm")
  }

  return token ? (
    <h2>you've already have an account</h2>
  ) : (
    <Wrapper>
      <div className="sigup_wrapper">
        <Typography component="h1" variant="h5">
          create an account
        </Typography>
        {/* <h2 style={{ color: "red" }}>{errorMsg}</h2> */}
        <h6
          style={{
            color: "red",
            fontSize: "1rem",
            fontWeight: "500",
            marginTop: "5px",
            marginBottom: "0",
            textAlign: "center",
          }}
        >
          {error
            ? error.data.message[0].messages[0].message
            : errorMsg
            ? errorMsg
            : null}
        </h6>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                data-cy="firstName"
                label="First Name"
                autoFocus
                value={user}
                onChange={(e) => setUser(e.target.value)}
                InputLabelProps={{
                  style: {
                    color: "#151515",
                    opacity: "0.8",
                    fontFamily: "var(--family)",
                    fontWeight: "500",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                data-cy="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={(e) => setLastName(e.target.value)}
                InputLabelProps={{
                  style: {
                    color: "#151515",
                    opacity: "0.8",
                    fontFamily: "var(--family)",
                    fontWeight: "500",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                data-cy="email"
                label="Email Address"
                name="email"
                id="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                InputLabelProps={{
                  style: {
                    color: "#151515",
                    opacity: "0.8",
                    fontFamily: "var(--family)",
                    fontWeight: "500",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                data-cy="confirm email"
                label="Confirm Email Address"
                name="confirm email"
                onChange={(e) => setConfirmEmail(e.target.value)}
                value={confirmEmail}
                InputLabelProps={{
                  style: {
                    color: "#151515",
                    opacity: "0.8",
                    fontFamily: "var(--family)",
                    fontWeight: "500",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type={showPassword ? "text" : "password"}
                name="password"
                label="Password"
                data-cy="password"
                autoComplete="current-password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                InputLabelProps={{
                  style: {
                    color: "#151515",
                    opacity: "0.8",
                    fontFamily: "var(--family)",
                    fontWeight: "500",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type={showPassword ? "text" : "password"}
                name="confirm password"
                label="Confirm Password"
                data-cy="confirm password"
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
                InputLabelProps={{
                  style: {
                    color: "#151515",
                    opacity: "0.8",
                    fontFamily: "var(--family)",
                    fontWeight: "500",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <button
            type="submit"
            className={`submit_button${email && pass && user && ` active`}`}
            data-cy="register-submit"
          >
            {!isLoading ? "create account" : "loading..."}
          </button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              margin: "1.5rem 0",
            }}
          >
            <div className="divider" />
          </div>
          <div style={{ display: "flex" }} className="account_wrapper">
            <h4>Already have an account?</h4>
            <Link to="/login" className="create_an_account">
              sign in
            </Link>
          </div>
        </form>
        {/* {providerNames.map((d, i) => (
        <ul style={{ listStyleType: "none" }}>
          <li key={d}>
            <LoginButton providerName={d} />
          </li>
        </ul>
      ))} */}
      </div>
      <div className="illustration">
        <StaticImage
          src="../images/sign.png"
          alt="certisured create account"
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  align-items: center;
  margin: 100px 0;
  min-height: 900px;
  @media (max-width: 767px) {
    margin: 50px 0;
  }
  span {
    font-family: var(--family);
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    color: #151515;
    @media (max-width: 479px) {
      font-size: 12px;
    }
  }
  .divider {
    width: 329.3px;
    height: 3px;
    background: radial-gradient(
      100.86% 10936973.14% at 100% 0%,
      #000 0%,
      #000 0%,
      rgba(0, 0, 0, 0) 0.01%,
      rgba(0, 0, 0, 0.19) 45.67%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .account_wrapper {
    align-items: center;
    h4 {
      font-weight: normal;
      font-size: 16px;
      margin: 0;
      color: #151515;
      @media (max-width: 479px) {
        font-size: 16px;
      }
    }
    .create_an_account {
      text-decoration: none;
      margin-left: 5px;
    }
  }
  .sigup_wrapper {
    grid-area: auto/2/auto/5;
    padding: 50px 0;
    width: 100%;
    @media (max-width: 991px) {
      grid-area: 2/2/3/11;
      padding-top: 50px;
    }
    h1 {
      font-weight: 600 !important;
      font-size: 22px !important;
      color: var(--secondaryColor) !important;
      text-transform: lowercase;
    }
  }
  .submit_button {
    color: var(--secondaryColor);
    background: none;
    padding: 15px 25px;
    min-width: 200px;
    border: none;
    border: 3px solid #2cde80;
    box-sizing: border-box;
    border-radius: 188px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    margin-top: 1rem;
    @media (max-width: 479px) {
      font-size: 12.9909px;
      min-width: 150px;
    }
  }
  .active {
    background-color: var(--thirdColor);
  }
  .illustration {
    grid-area: auto/6/auto/11;
    padding: 50px 0;
    @media (max-width: 991px) {
      grid-area: 1/2/2/11;
      padding-bottom: 0px;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 10px !important;
    background: #f8f8f8 !important;
  }
  .MuiInputBase-input {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #151515;
    opacity: 0.8;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0, 0, 0, 0.19) !important;
  }
  .MuiGrid-item {
    margin: 10px 0;
  }
`
